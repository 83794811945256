import React, { Fragment } from "react"
import ProjetosFinalistas2020 from '@/components/ProjetosFinalistas2020'
import ProjetosFinalistas2021 from '@/components/ProjetosFinalistas2021'

const EdicoesAnteriores = ({ title }) => {
  return (
    <Fragment>
      <section className="pageBanner" style={{ background: '#FAFAFA' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content">
                <h2 style={{ color: '#026FC2', marginBottom: 56 }}>{title}</h2>
                <h4 style={{ color: '#212121', marginBottom: 80 }}>Recomendamos que os participantes invistam em discussões relevantes com professores e mentores da sua escola e de outras esferas. Dessa forma, além estudar temas específicos em diversas verticais de empregabilidade, eles também terão acesso ao aprendizado de uma série de habilidades socioemocionais cruciais para o sucesso profissional no futuro.</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: '#FAFAFA' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ProjetosFinalistas2021 />
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: '#212121' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ProjetosFinalistas2020 />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default EdicoesAnteriores;
