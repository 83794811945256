import React from "react"
import 'react-accessible-accordion/dist/fancy-example.css'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading, 
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'

import AbsorvendoPDF from '../assets/apresentacoes-2020/absorvendo.pdf'
import EmbaqmaPDF from '../assets/apresentacoes-2020/embaqma.pdf'
import FacilitaEduPDF from '../assets/apresentacoes-2020/facilita-edu.pdf'
import GerassolPDF from '../assets/apresentacoes-2020/gerassol.pdf'
import LabdrivePDF from '../assets/apresentacoes-2020/labdrive.pdf'
import MoringarPDF from '../assets/apresentacoes-2020/moringar.pdf'
import NutrinimPDF from '../assets/apresentacoes-2020/nutrinim.pdf'
import SbeatPDF from '../assets/apresentacoes-2020/sbeat.pdf'
import SenectusPDF from '../assets/apresentacoes-2020/senectus.pdf'
import SolarisPDF from '../assets/apresentacoes-2020/solaris.pdf'
import TechSignalPDF from '../assets/apresentacoes-2020/techSignal.pdf'

const ProjetosFinalistas2021 = ({}) => {
  return (
    <div style={{ margin: '56px 0' }}>
    	<Accordion allowZeroExpanded>
				<AccordionItem uuid>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Absorvendo
									<p className="projetos-w-p">Lider do grupo: Karolyna de Oliveira Ramos</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Absorvente feminino sustentável à base da casca de mandioca</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56 ">
								<a className="inscreva-se-pink_btn PD-pink-1" href={AbsorvendoPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://admabsorvendo.wixsite.com/meusite"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Ecolorgenie
									<p className="projetos-w-p">Lider do grupo: Geovana de Brito Matos</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Tinta feita com óleo reciclado e alimentos orgânicos</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://projetoecolorgenie.wixsite.com/website/sobre"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Écolos
									<p className="projetos-w-p">Lider do grupo: Victor da Silveira</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Armação de óculos feita de plástico reciclado</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://equipeecolos.wixsite.com/website"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Embaqma
									<p className="projetos-w-p">Lider do grupo: Ana Júlia Martins Ciribeli</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Embalagens de bambu para queijos artesanais</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={EmbaqmaPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://embaqma.wixsite.com/embaqma"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Ester
									<p className="projetos-w-p">Lider do grupo: Filipe Belchior Bessa Zanovello</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Aplicativo de tradução simultânea para libras</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://sites.google.com/estudante.nacionalnet.com.br/projeto-ester/p%C3%A1gina-inicial"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Facilita.edu
									<p className="projetos-w-p">Lider do grupo: João Victor Lopes</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Plataforma digital para ligar professores a ferramentas educacionais</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={FacilitaEduPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://www.facilitaedu.com/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Gerassol
									<p className="projetos-w-p">Lider do grupo: Augusto Bisio de Souza</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Painéis de baixo custo, feitos à base de vidro reciclável, para produção de energia elétrica</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={GerassolPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://pgerassol.wixsite.com/gerassol"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Inspire Saber
									<p className="projetos-w-p">Lider do grupo: Mateus Duarte Dumont de Matos</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Plataforma digital para fomento de participação em iniciação científica e olimpíadas de conhecimento</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://inspiresaber.wixsite.com/inspiresaber"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Labdrive
									<p className="projetos-w-p">Lider do grupo: Mariana Garcia Casrilho</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Laboratório móvel de ciências para experimentos cientificos de Fundamental I</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={LabdrivePDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://labdriveciencias.wixsite.com/labdrive"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Leitura Arretada
									<p className="projetos-w-p">Lider do grupo: Natália Vicente Oliveira</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Aplicativo de incentivo à leitura e à valorização da cultura nordestina</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://arretadaleitura.wixsite.com/website-2020"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Moringar
									<p className="projetos-w-p">Lider do grupo: Joanna Alycia Campos de Oliveira</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Filtro redutor de poluentes gasosos à base de semente de moringa</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={MoringarPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://moringueiros.wixsite.com/moringar"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Nutrinim
									<p className="projetos-w-p">Lider do grupo: Cailany da Rocha Baraúna Cavalcante</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Jujuba para aguçar os sentidos do paladar e olfato, e ampliar a produção de saliva de pacientes com câncer</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={NutrinimPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="http://www.nutrinim.com/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									ONG Camaleão
									<p className="projetos-w-p">Lider do grupo: Maria Clara Abondancia Bertini</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Materiais socioeducacionais sustentáveis para desenvolvimento de pessoas com deficiência e ampliação das oportunidades</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://www.ongcamaleao.com/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									PlayEd
									<p className="projetos-w-p">Lider do grupo: Jaqueline Hashimoto Shie</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Aplicativo de reforço aos conteúdos de Fundamental II e Ensino Médio em formato gamificado</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="http://played.ezyro.com/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Projeto Higiene Para Todos
									<p className="projetos-w-p">Lider do grupo: André Luiz de Sousa Farias</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Creme antisséptico de baixo custo à base de juá</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							{/* <div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href="#" target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div> */}
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="http://higiene.himtecnologia.com/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Sbeat
									<p className="projetos-w-p">Lider do grupo: Laura Mello Camara</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Aplicativo de streaming de músicas adaptadas para usuários com deficiência auditiva</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={SbeatPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://sbeat.ensinu.com.br/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Senectus
									<p className="projetos-w-p">Lider do grupo: Camila Pedreira Baraúna</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Aplicativo multifuncional para facilitar o uso da tecnologia por pessoas de terceira idade</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={SenectusPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="http://appsenectus.com.br/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Solaris
									<p className="projetos-w-p">Lider do grupo: Cecília Brenda Dias Frota</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Sistema de dessalinização da água do mar através de energia solar</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={SolarisPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://projetosolaris.weebly.com/"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							<div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div style={{ color: '#ffffff' }}>
									Tech Signal
									<p className="projetos-w-p">Lider do grupo: Anna Catharina Nascimento Sena</p>
                </div>
                <div className="accordion-arrow-w" style={{ marginLeft: 'auto' }} />
              </div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="accordion-w-p">Plataforma digital de armazenamento de sinalários, vídeos, artigos e materiais de cunho acadêmico em Libras</p>
						<div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
							<div className="ML_32 MB-56">
								<a className="inscreva-se-pink_btn PD-pink-1" href={TechSignalPDF} target='_blank'>
									<span>Ver apresentação (PDF)</span>
								</a>
							</div>
							<div>
								<a 
									className="inscreva-se-pink_btn PD-pink-2" 
									href="https://thaianefcs64.wixsite.com/techsignal"
									target="_blanlk"
								>
									<span>Conheça o projeto</span>
								</a>
							</div>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
      </Accordion>
    </div>
  );
};

export default ProjetosFinalistas2021;
